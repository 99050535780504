import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MagnifyingGlass from "../svg/MagnifyingGlass";
import { InputField } from "../form/InputField";
import SubmitSuffix from "../button/SubmitSuffix";
import { get } from "../../helpers/api";
import { showSnackbar } from "../../helpers/notification";
import { IPlace } from "../../features/mapScreen/mapScreenInterface";
import {
	MsClearSearchPlaces,
	MsSetSearchPlaces,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import XMark from "../svg/XMark";
import { AnimatePresence, motion } from "framer-motion";
import { panTo, selectPlace } from "../../helpers/mapScreen";
import Loading from "../svg/Loading";

export function SearchPlaces() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [q, setQ] = useState<string>("");
	const mapScreen = useSelector(selectMapScreen);

  useEffect(() => {
		if (mapScreen.State === "CityHost") {
			setQ("");
		}
  }, [mapScreen.State]);

	/**
	 * Submit form, used when hitting enter
	 * @param e
	 */
	const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		searchPlace(q);
	};

	/**
	 * Search location based on query via ChApi
	 * @param query
	 */
	const searchPlace = (query: string) => {
		if (query.length) {
			dispatch(MsClearSearchPlaces);
			setLoading(true);
			get({ url: `map-screen/search-places`, params: { q: query } })
				.then((response) => {
					if (response.data && response.data.Success) {
						dispatch(MsSetSearchPlaces(response.data.Places));
						setLoading(false);
					} else {
						showSnackbar(response.data.Message, "error", "bottom-center");
					}
				})
				.catch((error) => {
					showSnackbar(error.response?.Message, "error", "bottom-center");
				});
		} else {
			showSnackbar(
				"Please enter what you are looking for.",
				"error",
				"bottom-center",
			);
		}
	};

	return (
		<>
			<AnimatePresence>
				{mapScreen.State === "Map" && (
					<motion.div
						initial={{ y: 300, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 300, opacity: 0 }}
						className="absolute bottom-4 left-4 right-4 z-10 rounded-md flex flex-col items-center text-white"
					>
						<form
							onSubmit={(e) => {
								handleFormSubmit(e);
							}}
							className="w-[400px] text-h3"
						>
							<InputField
								type="text"
								name="q"
								placeholder="What are you looking for?"
								defaultValue={q}
								value={q}
								onChange={(e) => {
									setQ(e.target.value);
								}}
								suffix={
									<SubmitSuffix
										onSubmit={() => searchPlace(q)}
										loading={loading}
									/>
								}
							/>
						</form>
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{loading && (
					<motion.div
						initial={{ x: -300, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: -300, opacity: 0 }}
						className="absolute bottom-16 left-4 right-4 w-full"
					>
						<div className="overflow-x-scroll w-full flex space-x-6 py-6 pr-4">
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, index) => (
								<div
									className="flex-shrink-0 relative bg-cityhub-900 border-2 border-cithyhub-500 rounded-md flex flex-col basis-[10%] justify-between items-center cursor-pointer drop-shadow-xl opacity-75"
									key={index}
								>
									<div className="w-full object-cover rounded-md aspect-[3/4] flex justify-center items-center">
										<Loading className="w-12 h-12 animate-loading text-white" />
									</div>
								</div>
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{!loading && mapScreen !== undefined && (
					<motion.div
						initial={{ x: 300, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: 300, opacity: 0 }}
						className="absolute bottom-16 left-4 right-4 w-full"
					>
						<div className="overflow-x-scroll w-full flex space-x-6 py-6 pr-4">
							{mapScreen.SearchPlaces?.map((place, index) => (
								<div
									className={`flex-shrink-0 relative rounded-md flex flex-col basis-[10%] justify-between items-center cursor-pointer drop-shadow-xl border-2 ${(mapScreen.SelectedPlace && mapScreen.SelectedPlace.GoogleId === place.GoogleId) ? 'bg-orange-500 border-orange-500 scale-105 transition-transform' : 'bg-cityhub-900 border-cithyhub-500'  }`}
									key={index}
									onClick={() => {
										panTo(place);
										selectPlace(place);
									}}
								>
									<img
										src={place.PhotoUrl}
										className="w-full object-cover rounded-md aspect-[3/4]"
									/>
									<div className={`absolute bottom-0 w-full p-2 flex flex-col rounded-b-md ${(mapScreen.SelectedPlace && mapScreen.SelectedPlace.GoogleId === place.GoogleId) ? 'bg-orange-500 text-white' : 'bg-cityhub-500 text-black' }`}>
										<div className="flex justify-between items-center">
											<span className="text-h5">{place.Name}</span>
										</div>
										<p className="">{place.Description}</p>
									</div>
								</div>
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
}
