import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import moment from "moment";
import { IMapScreenState, IPosition } from "./mapScreenInterface";

const initialState: IMapScreenState = {
	Customer: null,
	ChatRoomDocId: "",
	HotelDocId: "cZNWL7lYr7mkwY7RbDYB",
	Hotel: null,
	Hotels: [],
	Places: [],
	SearchPlaces: [],
	CuratedPlaces: [],
	FavoritePlaces: [],
	SelectedPlace: null,
	CityHost: null,
	Position: {
		Lat: 52.3672879,
		Lng: 4.8670989,
		Zoom: 10,
		Moment: moment().toISOString(),
	},
	GoogleId: null,
	State: "CityHost",
	PlacesState: "None",
};

export const mapScreenSlice = createSlice({
	name: "mapScreen",
	initialState,
	reducers: {
		MsSetCustomer: (
			state,
			action: PayloadAction<IMapScreenState["Customer"]>,
		) => {
			state.Customer = action.payload;
		},
		MsClearCustomer: (state) => {
			state.Customer = null;
		},
		MsSetHotel: (state, action: PayloadAction<IMapScreenState["Hotel"]>) => {
			state.Hotel = action.payload;
		},
		MsClearHotel: (state) => {
			state.Hotel = null;
		},
		MsSetChatRoomDocId: (
			state,
			action: PayloadAction<IMapScreenState["ChatRoomDocId"]>,
		) => {
			state.ChatRoomDocId = action.payload;
		},
		MsClearChatRoomDocId: (state) => {
			state.ChatRoomDocId = "";
		},
		MsSetHotels: (state, action: PayloadAction<IMapScreenState["Hotels"]>) => {
			state.Hotels = action.payload;
		},
		MsClearHotels: (state) => {
			state.Hotels = [];
		},
		MsSetPlaces: (state, action: PayloadAction<IMapScreenState["Places"]>) => {
			state.Places = action.payload;
		},
		MsClearPlaces: (state) => {
			state.Places = [];
		},
		MsSetSearchPlaces: (
			state,
			action: PayloadAction<IMapScreenState["SearchPlaces"]>,
		) => {
			state.SearchPlaces = action.payload;
		},
		MsClearSearchPlaces: (state) => {
			state.SearchPlaces = [];
		},
		MsSetCuratedPlaces: (
			state,
			action: PayloadAction<IMapScreenState["CuratedPlaces"]>,
		) => {
			state.CuratedPlaces = action.payload;
		},
		MsClearCuratedPlaces: (state) => {
			state.CuratedPlaces = [];
		},
		MsSetFavoritePlaces: (
			state,
			action: PayloadAction<IMapScreenState["FavoritePlaces"]>,
		) => {
			state.FavoritePlaces = action.payload;
		},
		MsClearFavoritePlaces: (state) => {
			state.FavoritePlaces = [];
		},
		MsSetSelectedPlace: (
			state,
			action: PayloadAction<IMapScreenState["SelectedPlace"]>,
		) => {
			state.SelectedPlace = action.payload;
		},
		MsSetCityHost: (
			state,
			action: PayloadAction<IMapScreenState["CityHost"]>,
		) => {
			state.CityHost = action.payload;
		},
		MsClearCityHost: (state) => {
			state.CityHost = null;
		},
		MsSetLatLng: (
			state,
			action: PayloadAction<{
				lat: IPosition["Lat"];
				lng: IPosition["Lng"];
				zoom: IPosition["Zoom"];
			}>,
		) => {
			state.Position.Lat = action.payload.lat;
			state.Position.Lng = action.payload.lng;
			state.Position.Zoom = action.payload.zoom;
			state.Position.Moment = moment().toISOString();
		},
		MsSetGoogleId: (
			state,
			action: PayloadAction<IMapScreenState["GoogleId"]>,
		) => {
			state.GoogleId = action.payload;
		},
		MsSetState: (state, action: PayloadAction<IMapScreenState["State"]>) => {
			state.State = action.payload;
		},
		MsSetPlacesState: (
			state,
			action: PayloadAction<IMapScreenState["PlacesState"]>,
		) => {
			state.PlacesState = action.payload;
		},
	},
});

export const {
	MsSetCustomer,
	MsClearCustomer,
	MsSetChatRoomDocId,
	MsClearChatRoomDocId,
	MsSetHotel,
	MsClearHotel,
	MsSetHotels,
	MsClearHotels,
	MsSetPlaces,
	MsClearPlaces,
	MsSetSearchPlaces,
	MsClearSearchPlaces,
	MsSetCuratedPlaces,
	MsClearCuratedPlaces,
	MsSetFavoritePlaces,
	MsClearFavoritePlaces,
  MsSetSelectedPlace,
	MsSetCityHost,
	MsClearCityHost,
	MsSetLatLng,
	MsSetGoogleId,
	MsSetState,
	MsSetPlacesState,
} = mapScreenSlice.actions;

// Selectors
export const selectMapScreen = (state: RootState) => state.mapScreen;

export default mapScreenSlice.reducer;
