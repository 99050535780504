import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearSnackbarMessage, selectSnackbar } from "../features/application/appSlice"
import { selectConfig } from "../features/config/configSlice"
import { showSnackbar } from "../helpers/notification"

export function Snackbar() {
  const [hidden, setHidden] = useState<boolean>(true)
  const [show, setShow] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const showTime: any = useRef(null)
  const config = useSelector(selectConfig)
  const snackbar = useSelector(selectSnackbar)
  const dispatch = useDispatch()

  const positions: { [key: string]: string } = {
    "top-center": "top-6 justify-center items-center",
    "bottom-center": "bottom-6 justify-center items-center",
  }

  const types: { [key: string]: string } = {
    error: "bg-red-600",
    success: "bg-green-600",
  }


  useEffect(() => {
    var channel = window.Echo.channel('snackbar.' + config.Property?.Abbreviation);
    channel.listen('.snackbar-message', function(data: any) {
      console.log(JSON.stringify(data));

      showSnackbar(data.message, data.success ? "success" : "error", "top-center")
    });
  }, [config]);
  

  useEffect(() => {
    if (snackbar.message !== "") {
      // Reset timeout
      if (showTime.current !== null) {
        clearTimeout(showTime.current)
      }

      // Show snack bar
      setHidden(false)
      setTimeout(() => {
        setShow(true)
      }, 100)

      showTime.current = setTimeout(() => {
        setShow(false)
        dispatch(clearSnackbarMessage())
        setTimeout(() => {
          setHidden(true)
        }, 100)
      }, 3000)
    } else {
      setShow(false)
      dispatch(clearSnackbarMessage())
    }
  }, [snackbar.timestamp])

  return (
    <div
      className={`absolute z-50 w-full flex px-12 ${(hidden) ? "hidden" : ""} ${
        positions[snackbar.position]
      }`}
    >
      <div
        className={`text-center py-4 px-8 rounded-xl transition-opacity duration-600 text-white 
      ${show ? "opacity-100" : "opacity-0"} ${types[snackbar.type]}
      `}
      >
        <span>{snackbar.message}</span>
      </div>
    </div>
  )
}
