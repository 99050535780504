import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import MapPin from "../svg/MapPin";
import { useCallback, useEffect, useRef, useState } from "react";

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import type { Marker } from "@googlemaps/markerclusterer";
import { useDispatch, useSelector } from "react-redux";
import {
	MsSetSelectedPlace,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import { IPlace } from "../../features/mapScreen/mapScreenInterface";
import { mergePlaces } from "../../helpers/base";
import Logo from "../svg/Logo";

export function Markers({ places }: { places: IPlace[][] }) {
	const dispatch = useDispatch();
	const map = useMap();
	const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
	const clusterer = useRef<MarkerClusterer | null>(null);
	const mapScreen = useSelector(selectMapScreen);
	const [uniquePlaces, setUniquePlaces] = useState<IPlace[]>([]);

	useEffect(() => {
		setUniquePlaces(mergePlaces(places));
	}, [places]);

	/**
	 * Initialize MarkerClusterer
	 */
	// useEffect(() => {
	// 	if (!map) return;
	// 	if (!clusterer.current) {
	// 		clusterer.current = new MarkerClusterer({ map });
	// 	}
	// }, [map]);

	/**
	 * Update markers
	 */
	// useEffect(() => {
	// 	clusterer.current?.clearMarkers();

	// 	clusterer.current?.addMarkers(Object.values(markers));
	// }, [markers]);

	/**
	 * Set markers
	 * @param marker
	 * @param key
	 * @returns
	 */

	// const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
	// 	setMarkers((markers) => {
	// 		if ((marker && markers[key]) || (!marker && !markers[key]))
	// 			return markers;

	// 		if (marker) {
	// 			return { ...markers, [key]: marker };
	// 		} else {
	// 			const { [key]: _, ...newMarkers } = markers;

	// 			return newMarkers;
	// 		}
	// 	});
	// }, []);

	return (
		<>
			{uniquePlaces.map((place, index) => (
				<AdvancedMarker
					position={{ lat: place.Lat, lng: place.Lng }}
					key={index}
					// ref={(marker) => setMarkerRef(marker, place.Id)}
					clickable={true}
					onClick={() => {
						dispatch(MsSetSelectedPlace(place));
					}}
				>
					<div className="relative">
						{mapScreen.Hotels.includes(place) && (
              <div className="p-3 bg-orange-300 rounded-full flex justify-center items-center">
							<Logo className="w-10 h-10 text-white" />
              </div>
						)}

						{(!mapScreen.Hotels.includes(place) && ( mapScreen.SelectedPlace === null ||
							mapScreen.SelectedPlace.GoogleId !== place.GoogleId)) && (
							<MapPin className="w-8 h-8 text-orange-600" />
						)}
            
						{(!mapScreen.Hotels.includes(place) &&mapScreen.SelectedPlace !== null &&
							mapScreen.SelectedPlace.GoogleId === place.GoogleId) && (
								<MapPin className="w-10 h-10 text-orange-500 animate-bounce" />
							)}
					</div>
				</AdvancedMarker>
			))}
		</>
	);
}
