import { useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import ConfigForm from "../../components/modal/ConfigForm"
import { useDispatch, useSelector } from "react-redux"
import { selectConfig } from "../../features/config/configSlice"
import { Snackbar } from "../../components/Snackbar"
import { LoadingOverlay } from "../../components/LoadingOverlay"
import { CheckActivity } from "../../components/CheckActivity"
import Cloud from "../../components/svg/Cloud"
import { Fullscreen } from "../../components/Fullscreen"
import Home from "../../components/svg/Home"
import { clearLoadingOverlay } from "../../features/application/appSlice"
import Bars3 from "../../components/svg/Bars3"
import XMark from "../../components/svg/XMark"
import { resetReservations } from "../../helpers/reservation"

export default function LayoutMapScreen() {
  const [configModal, setConfigModal] = useState<boolean>(false)
  const config = useSelector(selectConfig)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showCloseMessage, setShowCloseMessage] = useState<boolean>(false)
  const location = useLocation()

  return (
    <>
      {/* Snackbar and ovlerlay */}
      <Snackbar />
      <LoadingOverlay />

      {/* Main, where the app is rendered */}
      <main className="relative flex flex-col items-center h-screen px-24 py-12 w-full">
        {config.Loaded && <Outlet />}
      </main>

      {/* Config modal */}
      {configModal && (
        <ConfigForm
          onCancel={() => {
            setConfigModal(false)
          }}
        />
      )}

      {/* Check activity */}
      {location.pathname !== "/mapscreen" && (
        <CheckActivity
          callback={() => {
            resetReservations()
          }}
        />
      )}

      
      {/* Menu */}
      {location.pathname === "/mapscreen" && (
        <div
          className={`absolute top-4 left-4 z-50 p-2 flex flex-col space-y-4 bg-gray-600 text-white rounded-md ${
            showMenu ? "opacity-100" : "opacity-25"
          }`}
        >
          {!showMenu && <Bars3 onClick={() => setShowMenu(!showMenu)} />}
          {showMenu && (
            <>
              <XMark onClick={() => setShowMenu(!showMenu)} />
              <Home
                onClick={() => {
                  dispatch(clearLoadingOverlay())
                  document.location.href = "/"
                }}
                className="w-6 h-6"
              />
              <Cloud
                onClick={() => {
                  setConfigModal(true)
                  setShowMenu(!showMenu)
                }}
                className="w-6 h-6"
              />
              <Fullscreen
                className="w-6 h-6"
                callBack={() => setShowMenu(!showMenu)}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
