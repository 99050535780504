import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../features/config/configSlice";
import { showSnackbar } from "../../helpers/notification";
import { get } from "../../helpers/api";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchPlaces } from "../../components/mapScreen/SearchPlaces";
import { MapMap } from "../../components/mapScreen/MapMap";
import { FavoritePlaces } from "../../components/mapScreen/FavoritePlaces";
import {
	MsSetCityHost,
	MsSetCuratedPlaces,
	MsSetHotel,
	MsSetHotels,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import {
	panTo,
	toggleConnect,
	toggleFavoritePlace,
} from "../../helpers/mapScreen";
import { Places } from "../../components/mapScreen/Places";
import CityHostSlider from "../../components/mapScreen/CityHostSlider";
import { SelectedPlace } from "../../components/mapScreen/SelectedPlace";
import { MapScanWristband } from "../../components/mapScreen/MapScanWristband";
import { MyPlaces } from "../../components/mapScreen/MyPlaces";
import { doc, onSnapshot } from "firebase/firestore";
import {
	fireDb,
	getChatroomByDocId,
	updateHotelPlacesReservationId,
} from "../../helpers/firebase";
import { SetChatRoom } from "../../features/chatRoom/chatRoomSlice";
import { formatChatroom } from "../../helpers/chatRoom";
import CityHost from "../../components/mapScreen/CityHost";
import WorldMap from "../../components/svg/WorldMap";
import CuratedPlaces from "../../components/mapScreen/CuratedPlaces";
import { MapOverlay } from "../../components/mapScreen/MapOverlay";

export default function HomeMapScreen() {
	const dispatch = useDispatch();
	const config = useSelector(selectConfig);
	const mapScreen = useSelector(selectMapScreen);

	useEffect(() => {
		var channel = window.Echo.channel(
			"mapscreen." + config.Property?.Abbreviation,
		);
		channel.listen(".init", function (data: any) {
			console.log(JSON.stringify(data));
		});
	}, [config]);

	/**
	 * Init map,
	 * Get hotels
	 * Get places
	 * Set center to hotel
	 */
	useEffect(() => {
		get({ url: `map-screen/init`, params: {} })
			.then((response) => {
				if (response.data && response.data.Success) {
					dispatch(MsSetHotel(response.data.Hotel));
					dispatch(MsSetHotels(response.data.Hotels));
					dispatch(MsSetCuratedPlaces(response.data.CuratedPlaces));
					dispatch(MsSetCityHost(response.data.CityHost));
				} else {
					showSnackbar(response.data.Message, "error", "bottom-center");
				}
			})
			.catch((error) => {
				showSnackbar(error.response?.Message, "error", "bottom-center");
			});
	}, []);

	useEffect(() => {
		if (mapScreen.Hotel !== null) {
			panTo(mapScreen.Hotel);
			toggleFavoritePlace(mapScreen.Hotel);
		}
	}, [mapScreen.Hotel]);

	useEffect(() => {
		if (mapScreen.HotelDocId !== undefined) {
			const unsuscribe = onSnapshot(
				doc(fireDb, "hotels", mapScreen.HotelDocId),
				(doc: any) => {
					const data = doc.data();
					toggleConnect(data.placesReservationId);
				},
			);

			return () => {
				unsuscribe();
			};
		}
	}, [mapScreen.HotelDocId]);

	useEffect(() => {
		// 083EA42C-ADF4-41AE-AEF9-4B9E57824B6E
		if (
			mapScreen.ChatRoomDocId !== undefined &&
			mapScreen.ChatRoomDocId !== ""
		) {
			getChatroomByDocId(mapScreen.ChatRoomDocId).then((room) => {
				if (room !== null && room !== undefined) {
					dispatch(SetChatRoom(formatChatroom(room)));
				}
			});
		}
	}, [mapScreen.ChatRoomDocId]);

	const connect = () => {
		updateHotelPlacesReservationId(
			mapScreen.HotelDocId,
			"083EA42C-ADF4-41AE-AEF9-4B9E57824B6E",
		);
	};

	return (
		<div className="fixed inset-0 bg-cityhub-500">
			{mapScreen.Position.Lat && mapScreen.Position.Lng && (
				<MapMap />
			)}

			{mapScreen.State === "Map" && (
				<div className="absolute top-4 left-4 z-10 w-[400px] flex flex-col space-y-4">
					<MyPlaces />
					<SelectedPlace />
				</div>
			)}

			<CityHost />
			<MapScanWristband />
      <MapOverlay />
			<SearchPlaces />
			<CuratedPlaces />

			{mapScreen.State === "CityHost" && (
				<div
					className="absolute top-2 left-1/2 -translate-x-1/2 z-10 flex justify-start bg-black p-2 rounded-md text-white text-sm flex items-center cursor-pointer"
					onClick={() => {
						connect();
					}}
				>
					{"Connect"}
				</div>
			)}
		</div>
	);
}
