/**
 *
 * Mapscreen helpers
 */

import { store } from "../app/store"
import { IMapScreenState, IPlace } from "../features/mapScreen/mapScreenInterface"
import {
  MsClearSearchPlaces,
  MsSetChatRoomDocId,
  MsSetFavoritePlaces,
  MsSetGoogleId,
  MsSetLatLng,
  MsSetPlacesState,
  MsSetSearchPlaces,
  MsSetSelectedPlace,
  MsSetState,
} from "../features/mapScreen/mapScreenSlice"
import { post } from "./api"
import { convertChatRoom } from "./chatRoom"
import { addMessage, convertPlace, messageWithPlace } from "./firebase"
import { sendFCMMessage, showSnackbar } from "./notification"

/**
 * Toggle favorite place
 * @param place
 */
export const toggleFavoritePlace = (place: IPlace): void => {
  const { mapScreen } = store.getState()
  const places = mapScreen.FavoritePlaces.some(
    (p) => p.GoogleId === place.GoogleId,
  )
    ? mapScreen.FavoritePlaces.filter((p) => p.GoogleId !== place.GoogleId)
    : [...mapScreen.FavoritePlaces, place]
  store.dispatch(MsSetFavoritePlaces(places))
}

/**
 * Connect / disconnect
 */
export const toggleConnect = (chatRoomDocId: IMapScreenState["ChatRoomDocId"]) => {
if(chatRoomDocId === ""){
  store.dispatch(MsClearSearchPlaces())
  store.dispatch(MsSetSelectedPlace(null))
  store.dispatch(MsSetState("CityHost"));
  store.dispatch(MsSetPlacesState("Curated"));
} else {
  store.dispatch(MsSetState("Map"));
  store.dispatch(MsSetPlacesState("None"));
}
store.dispatch(MsSetChatRoomDocId(chatRoomDocId))
}


/**
 * Center map to place
 * @param place
 */
export const panTo = (place: IPlace): void => {
  store.dispatch(MsSetLatLng({ lat: place.Lat, lng: place.Lng, zoom: 14 }))
  store.dispatch(MsSetGoogleId(place.GoogleId))
}

/**
 * Select place and show
 * @param place 
 */
export const selectPlace = (place: IPlace): void => {
  store.dispatch(MsSetSelectedPlace(place))
}

/**
 * Send a place to a chatroom
 * @param place 
 */
export const sendPlace = (place: IPlace): void => {
  const { mapScreen, chatRoom } = store.getState()
  const chatPlace = convertPlace(place)
  const message = messageWithPlace(convertChatRoom(chatRoom), `Check out ${chatPlace.name}!`, chatPlace)
  
  // Add message to chatroom
  addMessage(chatRoom.Id, message)
  
  // Send notification to quest
  sendFCMMessage(chatRoom.NotificationToken, 'CityHub', `Check out ${chatPlace.name}!`)
}
