import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import ScanWristbandIcon from "../svg/ScanWristbandIcon";
import { AnimatePresence, motion } from "framer-motion";
import AppleStore from "../svg/AppleStore";
import GooglePlayStore from "../svg/GooglePlayStore";

export function MapScanWristband() {
	const mapScreen = useSelector(selectMapScreen);

	return (
		<>
			{mapScreen.State === "CityHost" && (
				<div className="absolute top-0 left-1/2 -translate-x-1/2 z-10">
					<AnimatePresence>
						{mapScreen.State === "CityHost" && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="relative"
							>
									<motion.div
										animate={{
											y: [20, 0, 20],
										}}
										transition={{
											duration: 1,
											ease: "easeInOut",
											times: [0, 0.2, 0.5],
											repeat: Infinity,
											repeatDelay: 1,
										}}
										className="relative h-screen flex flex-col space-y-2 justify-center items-center"
									>
										<ScanWristbandIcon />
										<div className="flex flex-col justify-center items-center space-y-4 rounded-xl">
											<span className="text-h1 text-center">
												Scan your wristband
											</span>
											<span className="text-h4 text-gray-800">
												Ask the CityHost
											</span>
											<div className="flex justify-between items-center space-x-4">
												<AppleStore />
												<GooglePlayStore />
											</div>
										</div>
									</motion.div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
		</>
	);
}
