import { useEffect, useRef, useState } from "react"
import Button from "./button/Button"

export const CheckActivity = ({ callback }: { callback: () => void }) => {
  const [overlay, setOverlay] = useState<boolean>(false)
  const [start, setStart] = useState<number>(Date.now())
  const [time, setTime] = useState<number>(Date.now())
  const countInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  const stillActive = () => {
    setOverlay(false)
    setStart(Date.now())
  };

  useEffect(() => {
    const resetLastActivity = () => {
      setStart(Date.now());
    };

    ["mousedown", "mousemove", "keydown", "scroll", "touchstart"].forEach(
      (event) => {
        // listen for mouse events
        window.addEventListener(
          event as keyof WindowEventMap,
          resetLastActivity
        );
      }
    );

    countInterval.current = setInterval(() => {
      const diff = Math.floor(time - start);
      setTime(Date.now);

      // Open overlay (2 min no activity)
      if (diff >= 1000 * 60 * 2) {
        setOverlay(true);
      }

      // Call calback (3 min no activity)
      if (diff >= 1000 * 60 * 3) {
        callback();
      }
    }, 1000); // Check every 5 seconds

    // cleanup
    return () => {
      if (countInterval.current) clearInterval(countInterval.current);
      ["mousedown", "mousemove", "keydown", "scroll", "touchstart"].forEach(
        (event) => {
          // listen for mouse events
          window.removeEventListener(
            event as keyof WindowEventMap,
            resetLastActivity
          );
        }
      );
    };
  }, [time, start, callback])

  return (
    <>
      {overlay && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-cityhub-green z-50 flex justify-center">
          <div className="flex flex-col justify-center items-center m-auto">
            <div className="text-h2 p-12 pb-8 text-center text-white">
              Are you still there?
            </div>
            <div className="w-full flex justify-center space-x-8">
              <Button onClick={() => {callback()}} text="No" size="small" type="button" />
              <Button onClick={() => {stillActive()}} text="Yes" size="small" type="button" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
