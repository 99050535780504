import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	APIProvider,
	Map,
	MapCameraChangedEvent,
	MapCameraProps,
} from "@vis.gl/react-google-maps";

import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import { Markers } from "./Markers";
import { mapStyle } from "../../helpers/mapStyle";

/**
 *  Documentation
 *  https://visgl.github.io/react-google-maps/examples/markers-and-infowindows
 */
export function MapMap() {
	const mapScreen = useSelector(selectMapScreen);

	const [cameraProps, setCameraProps] = useState<MapCameraProps>({
		center: { lat: mapScreen.Position.Lat, lng: mapScreen.Position.Lng },
		zoom: mapScreen.Position.Zoom,
	});

	/**
	 * Handle scroll and zoom
	 */
	const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
		setCameraProps(ev.detail);
	}, []);

	/**
	 * Handle external center
	 */
	useEffect(() => {
		setCameraProps({
			center: { lat: mapScreen.Position.Lat, lng: mapScreen.Position.Lng },
			zoom: mapScreen.Position.Zoom,
		});
	}, [
		mapScreen.Position.Lat,
		mapScreen.Position.Lng,
		mapScreen.Position.Zoom,
		mapScreen.Position.Moment,
	]);

	return (
		<div className="flex-1 h-full relative">
			<APIProvider
				apiKey={"AIzaSyD-Oq4N-rLJPfleetZYUy4QuN8usqPBbqY"}
				libraries={["marker"]}
			>
				<Map
					mapId={"739af084373f96fe"}
					// mapTypeId={"roadmap"}
					// styles={mapStyle}
					{...cameraProps}
					onCameraChanged={handleCameraChange}
					gestureHandling={"greedy"}
					disableDefaultUI={true}
					reuseMaps
				>
					<Markers places={[mapScreen.Hotels, mapScreen.Places, mapScreen.SearchPlaces, mapScreen.CuratedPlaces]} /> 
				</Map>
			</APIProvider>
			{/* <Markers places={mapScreen.Places} /> */}
			{/* <Markers places={mapScreen.CuratedPlaces} /> */}
		</div>
	);
}
